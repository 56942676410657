@import-normalize;
@import 'resets.css';

body {
  /* Needed for mobile version */
  padding-top: var(--safe-area-top);
  padding-bottom: env(safe-area-inset-bottom, var(--safe-area-bottom));
}

/*
  Needed to make toastify play nice with the banner notification. Currently
  toatify will add a width to the toast container which will make the banner
  notification not be centered on the screen. This CSS will only apply to
  the top-center position when a banners notifications is present.
*/
.Toastify__toast-container--top-center:has(.banner-notification) {
  width: fit-content;
  --toastify-toast-min-height: 0;

  .Toastify__toast,
  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast {
    border-radius: 8px;
  }
}
