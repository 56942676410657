html {
  box-sizing: border-box;
  font-size: 62.5%;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  overscroll-behavior: none;
}

input,
textarea,
button,
html,
body,
#root {
  font-family: Roboto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
